import crypto from 'crypto'
import Axios, { setHeaders } from './axios'
import moment from 'moment'

export const generateUniqueNumber = (howMany, type) => {
  let chars
  if (type === 'alphaNumber') {
    chars = 'ABCDEFGHIJKLMNOPQRSTUWXYZ0123456789'
  } else if (type === 'alphaNumber2') {
    chars = 'ABCDEFGHIJKLMNOPQRSTUWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  } else {
    chars = '0123456789'
  }
  var rnd = crypto.randomBytes(howMany),
    value = new Array(howMany),
    len = chars.length
  for (var i = 0; i < howMany; i++) {
    value[i] = chars[rnd[i] % len]
  }

  return value.join('')
}

export const roleDetails = () => {
  const modules = [
    { key: 'users', value: 'Users' },
    { key: 'devices', value: 'Devices' },
    { key: 'device_group', value: 'Device Groups' },
    { key: 'activity', value: 'Activity' },
    { key: 'support', value: 'Support' },
    { key: 'company_settings', value: 'Company Settings' },
    { key: 'change_password', value: 'Change Password' },
    { key: 'var_panel', value: 'Var Panel' },
    { key: 'billing_portal', value: 'Billing Portal' },
    { key: 'company', value: 'Company' },
    { key: 'role', value: 'Role' },
  ]

  const actions = [
    { key: 'permission_add', value: 'Create' },
    { key: 'permission_modifly', value: 'Modify' },
    { key: 'permission_view', value: 'View' },
    { key: 'permission_delete', value: 'Delete' },
  ]

  return { modules: modules, actions: actions }
}

export const createActivityLog = (
  summary,
  deviceId = '',
  description = '',
  deviceGroupId = '',
  token = null
) => {
  const logData = {
    operation_type: 'Admin',
    device_id: deviceId,
    device_group_id: deviceGroupId,
    summary: summary,
    activity_description: description,
  }

  const authCode = {
    headers: { Authorization: !token ? localStorage.getItem('token') : token },
  }

  setHeaders()
  Axios.post('../api/user/createActivityLog', logData, authCode)
    .then((response) => {
      if (response.status === 200) {
      } else {
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
      }
    })

  return true
}

export const createActivityLogWithoutLogin = (
  summary,
  deviceId = '',
  description = '',
  deviceGroupId = '',
  authData = ''
) => {
  if (authData) {
    var uname = authData.userName
    var companyId = authData.companyId
    var uid = authData.uid
  } else {
    var uname = ''
    var companyId = ''
    var uid = ''
  }

  const logData = {
    operation_type: 'Admin',
    device_id: deviceId,
    device_group_id: deviceGroupId,
    summary: summary,
    activity_description: description,
    userName: uname,
    companyId: companyId,
    uid: uid,
  }

  const authCode = {
    headers: { Authorization: localStorage.getItem('token') },
  }

  setHeaders()
  Axios.post('../api/user/createActivityLogWithoutLogin', logData, authCode)
    .then((response) => {
      if (response.status === 200) {
      } else {
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
      }
    })

  return true
}

export const getPageDetails = (pathName) => {
  const pageDetails = { isDashboard: true, title: '', icon: '' }
  const iconPath = '../assets/img/'
  const pages = [
    // users
    {
      key: 'users',
      title: 'Users',
      pathName: '/users',
      icon: iconPath + 'users_blue.svg',
    },
    {
      key: 'users',
      title: 'Create/Modify Users',
      pathName: '/addUser',
      icon: iconPath + 'users_blue.svg',
    },
    {
      key: 'users',
      title: 'Create/Modify Users',
      pathName: '/modifyUser',
      icon: iconPath + 'users_blue.svg',
    },
    // device
    {
      key: 'devices',
      title: 'Devices',
      pathName: '/devices',
      icon: iconPath + 'lock_single_blue.svg',
    },
    {
      key: 'devices',
      title: 'Devices',
      pathName: '/deviceDetails',
      icon: iconPath + 'lock_single_blue.svg',
    },
    // device groups
    {
      key: 'deviceGroups',
      title: 'Device Groups',
      pathName: '/deviceGroups',
      icon: iconPath + 'lock_multi_blue_ic.svg',
    },
    {
      key: 'deviceGroups',
      title: 'Create/Modify Device Groups',
      pathName: '/addDeviceGroup',
      icon: iconPath + 'lock_multi_blue_ic.svg',
    },
    {
      key: 'deviceGroups',
      title: 'Create/Modify Device Groups',
      pathName: '/modifyDeviceGroup',
      icon: iconPath + 'lock_multi_blue_ic.svg',
    },
    // activity
    {
      key: 'activity',
      title: 'Activity',
      pathName: '/activity',
      icon: iconPath + 'activity_blue_ic.svg',
    },
    {
      key: 'activityDetails',
      title: 'Activity Details',
      pathName: '/activityDetails',
      icon: iconPath + 'activity_blue_ic.svg',
    },
    // alerts
    {
      key: 'alerts',
      title: 'Alerts',
      pathName: '/alerts',
      icon: iconPath + 'alerts_blue.svg',
    },
    {
      key: 'addAlert',
      title: 'Add New Alert',
      pathName: '/addAlert',
      icon: iconPath + 'alerts_blue.svg',
    },
    
    // support
    {
      key: 'support',
      title: 'Support',
      pathName: '/support',
      icon: iconPath + 'support_blue.svg',
    },
    {
      key: 'support',
      title: 'Support Ticket Details',
      pathName: '/supportTicketDetails',
      icon: iconPath + 'ticket.svg',
    },
    {
      key: 'support',
      title: 'Support Ticket Details',
      pathName: '/supportTicketGPSDetails',
      icon: iconPath + 'ticket.svg',
    },

    // company setting
    {
      key: 'companySettings',
      title: 'Company Settings',
      pathName: '/companySettings',
      icon: iconPath + 'setting_blue.svg',
    },
    {
      key: 'scheduleOpen',
      title: 'Company Settings',
      pathName: '/scheduleOpen',
      icon: iconPath + 'setting_blue.svg',
    },
    {
      key: 'setFobProgramers',
      title: 'Company Settings',
      pathName: '/setFobProgrammers',
      icon: iconPath + 'setting_blue.svg',
    },
    {
      key: 'changePassword',
      title: 'Change Password',
      pathName: '/changePassword',
      icon: iconPath + 'setting_blue.svg',
    },
    {
      key: 'varPanel',
      title: 'VAR Panel',
      pathName: '/varPanel',
      icon: iconPath + 'company.svg',
    },
    {
      key: 'billingPortal',
      title: 'Billing Portal',
      pathName: '/billingPortal',
      icon: iconPath + 'dollar-icon.svg',
    },
    {
      key: 'invoiceDetails',
      title: 'Billing Portal',
      pathName: '/invoiceDetails',
      icon: iconPath + 'dollar-icon.svg',
    },
    {
      key: 'createCustomerInvoice',
      title: 'Create Customer Invoice',
      pathName: '/createCustomerInvoice',
      icon: iconPath + 'dollar-icon.svg',
    },

    {
      key: 'companyRelatedInvoiceDetail',
      title: 'Company related invoice detail',
      pathName: '/companyRelatedInvoiceDetail',
      icon: iconPath + 'dollar-icon.svg',
    },

    // companies
    {
      key: 'company',
      title: 'Companies',
      pathName: '/company',
      icon: iconPath + 'company.svg',
    },
    {
      key: 'addCompany',
      title: 'Create/Modify Companies',
      pathName: '/addCompany',
      icon: iconPath + 'company.svg',
    },
    {
      key: 'modifyCompany',
      title: 'Create/Modify Companies',
      pathName: '/modifyCompany',
      icon: iconPath + 'company.svg',
    },
    {
      key: 'modifyCompany',
      title: 'Company Mailer Setting',
      pathName: '/companyMailerSetting',
      icon: iconPath + 'company.svg',
    },
    {
      key: 'ota',
      title: 'Ota',
      pathName: '/addOta',
      icon: iconPath + 'ota1.png',
    },
    { key: 'ota', title: 'Ota', pathName: '/ota', icon: iconPath + 'ota1.png' },

    {
      key: 'viewCompanyRequests',
      title: 'Company Requests',
      pathName: '/viewCompanyRequests',
      icon: iconPath + 'company.svg',
    },
  ]

  pages.forEach((el) => {
    if (el.pathName == pathName) {
      pageDetails.isDashboard = false
      pageDetails.title = el.title
      pageDetails.icon = el.icon
    }
  })

  return pageDetails
}

export const getTimes = () => {
  var timeValue = '00:00'
  var endLimit = '23:59'
  var step = 5
  var options = []
  var lastValue
  var timeValueIsEarlier = true
  var timeValueIsLaterThanLastValue = true
  while (timeValueIsEarlier && timeValueIsLaterThanLastValue) {
    lastValue = timeValue
    timeValue = moment(timeValue, 'HH:mm').add(step, 'minutes').format('HH:mm')
    options.push(timeValue)
    timeValueIsEarlier =
      moment(timeValue, 'HH:mm').diff(moment(endLimit, 'HH:mm')) < 0
    timeValueIsLaterThanLastValue =
      lastValue === undefined
        ? true
        : moment(lastValue, 'HH:mm').diff(moment(timeValue, 'HH:mm')) < 0
  }

  return options
}

export const UTCDateTimeFormat = (dateTime) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  var localFormat = moment.tz(dateTime, timeZone)
  var utcFormat = moment.tz(localFormat, 'UTC')
  return utcFormat
}

export const phoneNuberFormate = (phoneNumber) => {
  const formatedNumber = phoneNumber
    ? phoneNumber.substring(0, 3) +
      '-' +
      phoneNumber.substring(3, 6) +
      '-' +
      phoneNumber.substring(6, phoneNumber.length)
    : ''
  return formatedNumber
}

export const is_login = () => {
  alert('Called')
}
