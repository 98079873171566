import React, { useEffect, Component, useState } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
} from 'react-router-dom'
import {
  Group,
  Person,
  Devices,
  DevicesOther,
  Description,
  ContactSupport,
  Settings,
} from '@material-ui/icons'
import { withSnackbar } from 'notistack'
import Loader from './PageLoader'
import { connect, useSelector } from 'react-redux'
import Footer from './footer'
import dictionary from './store/dictionary.json'

// class Dashboard extends Component  {
const Dashboard = (props) => {
  const { permissionDetails } = useSelector((state) => state.auth)

  // render() {
  const [pageLoading, setPageLoading] = React.useState(true)

  const activeMenu = (menuName) => {
    localStorage.setItem('selectedMenu', menuName)
  }

  const history = useHistory()
  if (localStorage.getItem('needsRefresh') == 'True') {
    localStorage.setItem('needsRefresh', 'False')
    history.go(0)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setPageLoading(false)
    }, 1000)
  }, [])

  return (
    <div>
      {pageLoading ? <Loader /> : null}

      <div className="mt-4">
        <div className="row" style={{ minHeight: 530 }}>
          {permissionDetails.permission_all ||
          permissionDetails.permission_module.users ? (
            <div className="col-md-4">
              <div className="card card-stats">
                <div className="card-body">
                  <Link to="/users" onClick={() => activeMenu('user')}>
                    <div className="row">
                      <div className="col text-center">
                        <img
                          alt="Logo"
                          src="../assets/img/users_blue.svg"
                          style={{ height: 120, width: 120 }}
                        />
                        <p className="h2 font-weight-bold mb-0">
                          {
                            dictionary[localStorage.getItem('language')]
                              .users_title
                          }
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}

          {permissionDetails.permission_all ||
          permissionDetails.permission_module.devices ? (
            <div className="col-md-4">
              <div className="card card-stats">
                <div className="card-body">
                  <Link to="/devices" onClick={() => activeMenu('device')}>
                    <div className="row">
                      <div className="col text-center">
                        <img
                          alt="Logo"
                          src="../assets/img/lock_single_blue.svg"
                          style={{ height: 120, width: 120 }}
                        />
                        <p className="h2 font-weight-bold mb-0">
                          {
                            dictionary[localStorage.getItem('language')]
                              .devices_title
                          }
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}

          {permissionDetails.permission_all ||
          permissionDetails.permission_module.device_group ? (
            <div className="col-md-4">
              <div className="card card-stats">
                <div className="card-body">
                  <Link
                    to="/deviceGroups"
                    onClick={() => activeMenu('deviceGroup')}
                  >
                    <div className="row">
                      <div className="col text-center">
                        <img
                          alt="Logo"
                          src="../assets/img/lock_multi_blue_ic.svg"
                          style={{ height: 120, width: 120 }}
                        />
                        <p className="h2 font-weight-bold mb-0">
                          {
                            dictionary[localStorage.getItem('language')]
                              .device_groups_title
                          }
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}

          {permissionDetails.permission_all ||
          permissionDetails.permission_module.activity ? (
            <div className="col-md-4">
              <div className="card card-stats">
                <div className="card-body">
                  <Link to="/activity">
                    <div className="row">
                      <div className="col text-center">
                        <img
                          alt="Logo"
                          src="../assets/img/activity_blue_ic.svg"
                          style={{ height: 120, width: 120 }}
                        />
                        <p className="h2 font-weight-bold mb-0">
                          {
                            dictionary[localStorage.getItem('language')]
                              .activity_title
                          }
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}

          {permissionDetails.permission_all ||
          permissionDetails.permission_module.activity ? (
            <div className="col-md-4">
              <div className="card card-stats">
                <div className="card-body">
                  <Link to="/alerts">
                    <div className="row">
                      <div className="col text-center">
                        <img
                          alt="Logo"
                          src="../assets/img/alerts_blue.svg"
                          style={{ height: 120, width: 120 }}
                        />
                        <p className="h2 font-weight-bold mb-0">
                          {
                            dictionary[localStorage.getItem('language')]
                              .alert_title
                          }
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}

          {permissionDetails.permission_all ||
          permissionDetails.permission_module.support ? (
            <div className="col-md-4">
              <div className="card card-stats">
                <div className="card-body">
                  <a href="/support">
                    <div className="row">
                      <div className="col text-center">
                        <img
                          alt="Logo"
                          src="../assets/img/support_blue.svg"
                          style={{ height: 120, width: 120 }}
                        />
                        <p className="h2 font-weight-bold mb-0">
                          {
                            dictionary[localStorage.getItem('language')]
                              .support_title
                          }
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}

          {permissionDetails.permission_all ||
          permissionDetails.permission_module.company_settings ? (
            <div className="col-md-4">
              <div className="card card-stats">
                <div className="card-body">
                  <Link to="/companySettings">
                    <div className="row">
                      <div className="col text-center">
                        <img
                          alt="Logo"
                          src="../assets/img/setting_blue.svg"
                          style={{ height: 120, width: 120 }}
                        />
                        <p className="h2 font-weight-bold mb-0">
                          {
                            dictionary[localStorage.getItem('language')]
                              .company_settings_title
                          }
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}

          {permissionDetails.permission_all ||
          permissionDetails.permission_module.company ? (
            <div className="col-md-4">
              <div className="card card-stats">
                <div className="card-body">
                  <Link to="/company">
                    <div className="row">
                      <div className="col text-center">
                        <img
                          alt="Company"
                          src="../assets/img/company.svg"
                          style={{ height: 120, width: 120 }}
                        />
                        <p className="h2 font-weight-bold mb-0">Company</p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}

          {permissionDetails.permission_all ||
          permissionDetails.permission_module.company ? (
            <div className="col-md-4">
              <div className="card card-stats">
                <div className="card-body">
                  <Link to="/ota">
                    <div className="row">
                      <div className="col text-center">
                        <img
                          alt="Company"
                          src="../assets/img/ota1.png"
                          style={{ height: 120, width: 120 }}
                        />
                        <p className="h2 font-weight-bold mb-0">OTA</p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}

          {permissionDetails.permission_all ||
          permissionDetails.permission_module.company ? (
            <div className="col-md-4">
              <div className="card card-stats">
                <div className="card-body">
                  <Link to="/deviceType">
                    <div className="row">
                      <div className="col text-center">
                        <img
                          alt="Company"
                          src="../assets/img/lock_single_blue.svg"
                          style={{ height: 120, width: 120 }}
                        />
                        <p className="h2 font-weight-bold mb-0">Device Type</p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}

          {permissionDetails.permission_all ||
          permissionDetails.permission_module.company ? (
            <div className="col-md-4">
              <div className="card card-stats">
                <div className="card-body">
                  <a href="/adminSupport">
                    <div className="row">
                      <div className="col text-center">
                        <img
                          alt="Logo"
                          src="../assets/img/support_blue.svg"
                          style={{ height: 120, width: 120 }}
                        />
                        <p className="h2 font-weight-bold mb-0">
                          {
                            dictionary[localStorage.getItem('language')]
                              .support_title
                          }
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>

        {/* <Footer /> */}
      </div>
    </div>
  )
  // }
}

// export default Dashboard

export default connect()(withSnackbar(Dashboard))
