import React, { useEffect, useState, useRef, useMemo } from 'react'
import { useHistory, Link } from 'react-router-dom'
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Box,
  Divider,
  Typography,
  FormControl,
  InputLabel,
  Select,
  RadioGroup,
  Radio,
  FormHelperText,
} from '@material-ui/core'
import Switch from '@material-ui/core/Switch'
import 'react-toastify/dist/ReactToastify.css'
import PropTypes from 'prop-types'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { connect, useSelector, useDispatch } from 'react-redux'
import {
  activityList,
  resetErrorAndSuccessState,
  timeZoneList,
  addAlert,
  sendTestEmail,
} from '../store/actions/userActions'
import { companyListForUsers } from '../store/actions/companyActions'
import { withSnackbar } from 'notistack'
import validator from 'validator'
import DateFnsUtils from '@date-io/date-fns'
import { Delete } from '@material-ui/icons'
import HelpIcon from '@material-ui/icons/Help'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Loader from '../PageLoader'
import Footer from '../footer'
import Submenu from '../header_sub_menu'
import { generateUniqueNumber } from '../helper/commonHelper'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import InputMask from 'react-input-mask'
import { getCompanySettings } from '../store/actions/companySettingsActions'
import TimePicker from 'rc-time-picker'
import dictionary from '../store/dictionary.json'
import moment from 'moment'
// confirm dialog
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

const CheckboxWithGreenCheck = withStyles({
  root: {
    '&$checked': {
      '& .MuiIconButton-label': {
        position: 'relative',
        zIndex: 0,
      },
      '& .MuiIconButton-label:after': {
        content: '""',
        left: 5,
        top: 5,
        height: 14,
        width: 14,
        position: 'absolute',
        backgroundColor: '#1e4ca1',
        zIndex: 0,
      },
    },
  },
  checked: {},
})(Checkbox)

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  primary: {
    main: '#1e4ca1',
  },
  disabledColor: {
    background: '#E8E8E8',
  },
  imgSize: {
    width: '20px',
    marginRight: '10px',
    cursor: 'pointer',
  },
  lockImgSize: {
    cursor: 'pointer',
    width: 25,
    marginRight: 10,
  },
}))

const AddUsers = (props) => {
  const { enqueueSnackbar, error, success, loading } = props

  const classes = useStyles()
  const { activity_List } = useSelector((state) => state.user)
  const { timeZone_List } = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(activityList())
    dispatch(timeZoneList())
    dispatch(getCompanySettings())
  }, [dispatch])

  useEffect(() => {
    error && enqueueSnackbar(error, { variant: 'error' })
  }, [error])

  useEffect(() => {
      success && enqueueSnackbar(success, { variant: 'success' })
      dispatch(resetErrorAndSuccessState())
      if (success == 'Email Sent') {
        handleClose()
      }
    }, [success])

  useEffect(() => {
    const timer = setTimeout(() => {
      setPageLoading(false)
    }, 1000)
  }, [])

  const [pageLoading, setPageLoading] = React.useState(true)
  const [conditions, setConditions] = useState("000")
  const [scheduleValue, setScheduleValue] = useState(null)
  const [values, setValues] = React.useState({})
  const [formValidation, setFormValidation] = React.useState({})
  const [dispatchStartTime, setDispatchStartTime] = React.useState()
  const [dispatchEndTime, setDispatchEndTime] = React.useState()
  const [confirmOpen, setConfirmOpen] = React.useState({
    show: false,
    item: {},
    loadingPopup: false,
  })
  
  const handleClose = () => {
    setConfirmOpen({
      show: false,
      item: {},
      loadingPopup: false,
    })
  }

  const sendEmail = () => {
    if (confirmOpen.item) {
      setConfirmOpen({ ...confirmOpen, loadingPopup: true })
      const item = confirmOpen.item
      dispatch(sendTestEmail(item))
    }
  }

  const handleValidation = () => {
    let isError = false
    const formerr = { }

    if (!values.displayName) {
      isError = true
      formerr.displayName =
        dictionary[localStorage.getItem('language')].display_name_field_name +
        ' ' +
        dictionary[localStorage.getItem('language')].required_field_tooltip
      setFormValidation(formerr)
    }

    if (!values.activity || values.activity.length == 0) {
      isError = true
      formerr.activity =
        dictionary[localStorage.getItem('language')].add_alert_activity_dropdown +
        ' ' +
        dictionary[localStorage.getItem('language')].required_field_tooltip
      setFormValidation(formerr)
    } else {
      if (values.activity.alert_conditions.substring(0, 1) == '1' && (!values.created_by_users || values.created_by_users.length == 0)) {
        isError = true
        formerr.created_by_users =
          dictionary[localStorage.getItem('language')].add_alert_cond_created_by_title +
          ' ' +
          dictionary[localStorage.getItem('language')].required_field_tooltip
        setFormValidation(formerr)
      }
  
      if (values.activity.alert_conditions.substring(1, 2) == '1' && (!values.target_users || values.target_users.length == 0)) {
        isError = true
        formerr.target_users =
          dictionary[localStorage.getItem('language')].add_alert_cond_target_user_title +
          ' ' +
          dictionary[localStorage.getItem('language')].required_field_tooltip
        setFormValidation(formerr)
      }
  
      if (values.activity.alert_conditions.substring(2, 3) == '1' && (!values.devices || values.devices.length == 0)) {
        isError = true
        formerr.devices =
          dictionary[localStorage.getItem('language')].add_alert_cond_devices_title +
          ' ' +
          dictionary[localStorage.getItem('language')].required_field_tooltip
        setFormValidation(formerr)
      }
    }

    if (!values.schedule) {
      isError = true
      formerr.schedule =
        dictionary[localStorage.getItem('language')].add_alert_step_3_required +
        ' ' +
        dictionary[localStorage.getItem('language')].required_field_tooltip
      setFormValidation(formerr)
    }

    if (values.schedule == 'custom') {
      if (!values.startDate) {
        isError = true
        formerr.startDate =
          dictionary[localStorage.getItem('language')].start_date_field_name +
          ' ' +
          dictionary[localStorage.getItem('language')].required_field_tooltip
        setFormValidation(formerr)
      }

      if (!values.isNeverEnd && !values.endDate) {
        isError = true
        formerr.endDate =
          dictionary[localStorage.getItem('language')].end_date_field_name +
          ' ' +
          dictionary[localStorage.getItem('language')].required_field_tooltip
        setFormValidation(formerr)
      }
      
      if (!values.mon && !values.tue && !values.wed && !values.thu &&
        !values.fri && !values.sat && !values.sun
      ) {
        isError = true
        formerr.days =
          dictionary[localStorage.getItem('language')].days_error_tooltip
        setFormValidation(formerr)
      }

      if (!values.isAllDay && !values.startTime) {
        isError = true
        formerr.endTime =
              dictionary[localStorage.getItem('language')].start_time_field_name +
              ' ' +
              dictionary[localStorage.getItem('language')].and +
              ' ' +
              dictionary[localStorage.getItem('language')].end_time_field_name +
              ' ' +
              dictionary[localStorage.getItem('language')]
                .required_field_tooltip_feminine
        setFormValidation(formerr)
      }

      if (!values.isAllDay && !values.endTime) {
        isError = true
        formerr.endTime =
              dictionary[localStorage.getItem('language')].start_time_field_name +
              ' ' +
              dictionary[localStorage.getItem('language')].and +
              ' ' +
              dictionary[localStorage.getItem('language')].end_time_field_name +
              ' ' +
              dictionary[localStorage.getItem('language')]
                .required_field_tooltip_feminine
        setFormValidation(formerr)
      }

      if (!values.zone) {
        isError = true
        formerr.zone =
          dictionary[localStorage.getItem('language')].add_alert_step_3_required +
          ' ' +
          dictionary[localStorage.getItem('language')].required_field_tooltip
        setFormValidation(formerr)
      }
    }
    if (!values.emails || values.emails.length == 0) {
      isError = true
      formerr.emails =
        dictionary[localStorage.getItem('language')].add_alert_step_4_subtitle +
        ' ' +
        dictionary[localStorage.getItem('language')].required_field_tooltip
      setFormValidation(formerr)
    }

    return isError
  }

  const handleChangeSelection = (value, type) => {
    setValues({ ...values, [type]: value })
    const formerr = { ...formValidation }
    formerr[type] = null
    setFormValidation(formerr)

    if (type == 'activity') {
      if (value) {
        setConditions(value.alert_conditions)
      } else {
        setConditions("000")
      }
    }
  }

  const handleChange = (prop) => (event) => {
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value
    setValues({ ...values, [prop]: value })
    const formerr = { ...formValidation }
    formerr[event.target.name] = null
    setFormValidation(formerr)
  }

  const handleChangeSchedule = (event, type) => {
    if (event) {
      setFormValidation({ ...formValidation, [type]: null })

      if (type === 'startDate') {
        setValues({ ...values, startDate: event })
      } else if (type === 'endDate') {
        setValues({ ...values, endDate: event })
      } else if (type === 'startTime') {
        setValues({ ...values, startTime: event })
      } else if (type === 'endTime') {
        setValues({ ...values, endTime: event })
      }
    }
  }

  const handleChangeSchedule2 = (prop) => (event) => {
    const value =
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
    
    // if is never end is checked, clear the end date
    if (event.target.name == 'isNeverEnd' && value == true) {
      values['endDate'] = null
    }

    if (event.target.name == 'isAllDay' && value == true) {
      values['startTime'] = null
      values['endTime'] = null
      setDispatchStartTime(null)
      setDispatchEndTime(null)
    }

    setValues({ ...values, [prop]: value })
    const formerr = { ...formValidation }
    formerr[event.target.name] = null

    // reset the error for the start and end time when the never end is checked or unchecked
    if (event.target.name == 'isAllDay') {
      formerr['startTime'] = null
      formerr['endTime'] = null
    }
    // reset the error for end date when the never end is checked or unchekced
    if (event.target.name == 'isNeverEnd') {
      formerr['endDate'] = null
    }

    if (prop == 'schedule') {
      let scheduleReset = { ...values }
      scheduleReset['schedule'] = value

      scheduleReset['startDate'] = moment()
      scheduleReset['endDate'] = ''

      scheduleReset['startTime'] = ''
      scheduleReset['endTime'] = ''

      scheduleReset['mon'] = true
      scheduleReset['tue'] = true
      scheduleReset['wed'] = true
      scheduleReset['thu'] = true
      scheduleReset['fri'] = true
      scheduleReset['sat'] = true
      scheduleReset['sun'] = true

      scheduleReset['isNeverEnd'] = true
      scheduleReset['isAllDay'] = true
      setValues(scheduleReset)
    }

    if (
      prop == 'mon' ||
      prop == 'tue' ||
      prop == 'wed' ||
      prop == 'thu' ||
      prop == 'fri' ||
      prop == 'sat' ||
      prop == 'sun'
    ) {
      formerr['days'] = null
    }
    setFormValidation(formerr)
  }

  const handleValueChange = (event, type) => {
    if (type == 'startTime') {
      if (event) {
        // var val = event.format('HH:mm')
        // setValues({ ...values, startTime: val })
        setValues({ ...values, startTime: event })
      }
      setDispatchStartTime(event)
    } else if (type == 'endTime') {
      if (event) {
        // var val = event.format('HH:mm')
        // setValues({ ...values, endTime: val })
        setValues({ ...values, endTime: event })
      }
      setDispatchEndTime(event)
    }
  }

  const handleSubmitClick = (e) => {
    e.preventDefault()
    if (handleValidation()) return false

    let val = {...values}

    if (val.startDate)
      val.startDate = formatDate(val.startDate)
    if (val.endDate)
      val.endDate = formatDate(val.endDate)
    if (val.startTime)
      val.startTime = formatTime(val.startTime)
    if (val.endTime)
      val.endTime = formatTime(val.endTime)

    dispatch(addAlert(val, history))
  }

  const handleEmailClick = (e) => {
    e.preventDefault()
    if (handleValidation()) return false

    let emailValues = { ...values }

    if (emailValues.startDate)
      emailValues.startDate = formatDate(emailValues.startDate)
    if (emailValues.endDate)
      emailValues.endDate = formatDate(emailValues.endDate)
    if (emailValues.startTime)
      emailValues.startTime = formatTime(emailValues.startTime)
    if (emailValues.endTime)
      emailValues.endTime = formatTime(emailValues.endTime)

    setConfirmOpen({
      show: true,
      item: emailValues,
      loadingPopup: false,
    })
  }

  const formatDate = (ts) => {
    let date = new Date(ts)
    let m = "00" + (date.getMonth() + 1)
    m = m.substring(m.length - 2)
    let d = "00" + date.getDate()
    d = d.substring(d.length - 2)
    return date.getFullYear() + "-" + m + "-" + d
  }

  const formatTime = (ts) => {
    let date = new Date(ts)
    let h = "00" + date.getHours()
    h = h.substring(h.length - 2)
    let m = "00" + date.getMinutes()
    m = m.substring(m.length - 2)
    return h + ":" + m + ":00"
  }

  const isEndDateDisabled = useMemo(() => {
    return values['schedule'] == 'any' || values['isNeverEnd'] == true
  }, [values, scheduleValue])

  const isTimeDisabled = useMemo(() => {
    return values['schedule'] == 'any' || values['isAllDay'] == true
  }, [values, scheduleValue])

  const setDefaultTimeZone = (state) => {
    if (
      !values.zone &&
      state.companySettings?.companySettingsDetails?.default_time_zone_id
    ) {
      setValues({
        ...values,
        zone: state.companySettings?.companySettingsDetails
          ?.default_time_zone_id,
      })
    }
  }

  const { companySettingsDetails } = useSelector((state) => {
    setDefaultTimeZone(state)
    return state.companySettings
  })

  const filter = createFilterOptions();

  return (
    <div>
      {pageLoading ? <Loader /> : null}

      <Submenu />

      <div className="Contborder">
        <div className="row">
          <div className="col-md-12">
            <div className="card" style={{ marginBottom: 0 }}>
              {/* Back to Alert List button */}
              <div
                className="row align-items-center"
                style={{ padding: '12px 25px 0 0' }}
              >
                <div className="col-lg-6"></div>
                <div className="col-lg-6">
                  <Link class="btn btn-primary float-right" to="/alerts">
                    {
                      dictionary[localStorage.getItem('language')]
                        .alert_list_button
                    }
                  </Link>
                </div>
              </div>
              <div className="card-body">
                {/* Display Name entry field */}
                <div className="row mb-3">
                  <div className="col-md-4">
                    <TextField
                      id="lname"
                      fullWidth
                      label={
                        dictionary[localStorage.getItem('language')]
                          .display_name_field_name +
                        ' ' +
                        dictionary[localStorage.getItem('language')]
                          .required_field_name
                      }
                      variant="outlined"
                      name="displayName"
                      value={values.displayName}
                      onChange={handleChange('displayName')}
                      error={!!formValidation.displayName}
                      helperText={formValidation.displayName}
                    />
                  </div>
                </div>
                {/* 1. Select a Trigger */}
                <div className="row mb-3 align-middle">
                  <div
                    className="col-md-4"
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      className="mb-3"
                    >
                      <strong>
                        {
                          dictionary[localStorage.getItem('language')]
                            .add_alert_step_1_header
                        }
                      </strong>
                    </Typography>
                    <Card
                      variant="outlined"
                      style={{ border: '2px solid #b0b0b0', background: '#F8F8F8' }}
                    >
                      <CardContent>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          className=""
                        >
                          <Autocomplete
                            id="combo-box-demo"
                            options={activity_List.activities}
                            onChange={(event, value) =>
                              handleChangeSelection(value, 'activity')
                            }
                            getOptionLabel={(option) => option.name + " (" + option.type + ")"
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={
                                  dictionary[
                                    localStorage.getItem('language')
                                  ].add_alert_activity_dropdown
                                }
                                variant="outlined"
                                error={!!formValidation.activity}
                              />
                            )}
                            renderOption={(option) => (
                              <div style={{width: '100%'}} className="row">
                                <div className="col-md-10">
                                  {option.name}
                                </div>
                                <div className="col-md-2" style={{ 
                                    float: 'right', 
                                    textAlign: 'center', 
                                    verticalAlign: 'middle',
                                    color: 'white', 
                                    background: option.type == "App" ?
                                      '#2B593F'
                                      : option.type == "Sentinel" ?
                                      '#1e4ca1'
                                      : '#7b4a41'
                                    ,
                                    margin: '5px 0px', 
                                    padding: 0,
                                    fontSize: '80%',
                                    borderRadius: '3px'
                                }}>
                                  <strong>{option.type}</strong>
                                </div>
                              </div>
                            )}
                            style={{ background: 'white' }}
                          />
                          <FormHelperText style={{ color: 'red' }}>
                            {formValidation.activity}
                          </FormHelperText>
                        </FormControl>
                      </CardContent>
                    </Card>
                  </div>
                </div>
                {/* 2. Select Conditions */}
                <div className="row mb-3">
                  {/* Dynamic background gray box based on the number conditions we are using */}
                  <div className={"col-md-" + (Math.max((conditions.match(new RegExp("1", "g")) || []).length, 1) * 4)}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      className="mb-3"
                    >
                      <strong>
                        {
                          dictionary[localStorage.getItem('language')]
                            .add_alert_step_2_header
                        }
                      </strong>
                    </Typography>
                    <Card
                      variant="outlined"
                      style={{ border: '2px solid #b0b0b0', background: '#F8F8F8' }}
                    >
                      <CardContent>
                        <div className="row">
                          {/* Dynamic condition options and sizes based on number of used options */}
                          {conditions.substring(0, 1) == '1' ? (
                            <div className={"col-md-" + (12 / (Math.max((conditions.match(new RegExp("1", "g")) || []).length, 1)))}>
                              <div className="row">
                                <div className="col">
                                  <Typography
                                      variant="subtitle2"
                                      gutterBottom
                                      className="mb-3 text-center"
                                    >
                                    <strong>
                                      {
                                        dictionary[localStorage.getItem('language')]
                                          .add_alert_cond_created_by_title
                                      }
                                    </strong>
                                    <br></br>
                                    <i>
                                      {
                                        dictionary[localStorage.getItem('language')]
                                          .add_alert_cond_created_by_subtitle
                                      }
                                      <strong>
                                        {
                                          dictionary[localStorage.getItem('language')]
                                            .add_alert_cond_created_by_subtitle_bold
                                        }
                                      </strong>
                                    </i>
                                  </Typography>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col">
                                  <FormControl
                                    variant="outlined"
                                    fullWidth
                                  >
                                    <Autocomplete
                                      id="combo-box-demo"
                                      multiple
                                      options={activity_List.users}
                                      groupBy={(option) => option.type}
                                      getOptionLabel={(option) => option.name}
                                      onChange={(event, value) =>
                                        handleChangeSelection(value, 'created_by_users')
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label={
                                            dictionary[
                                              localStorage.getItem('language')
                                            ].add_alert_cond_created_by_dropdown
                                          }
                                          variant="outlined"
                                          error={!!formValidation.created_by_users}
                                        />
                                      )}
                                      style={{ background: 'white' }}
                                    />
                                    <FormHelperText style={{ color: 'red' }}>
                                      {formValidation.created_by_users}
                                    </FormHelperText>
                                  </FormControl>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                          {conditions.substring(1, 2) == '1' ? (
                            <div className={"col-md-" + (12 / (Math.max((conditions.match(new RegExp("1", "g")) || []).length, 1)))}>
                              <div className="row">
                                <div className="col">
                                  <Typography
                                      variant="subtitle2"
                                      gutterBottom
                                      className="mb-3 text-center"
                                    >
                                    <strong>
                                      {
                                        dictionary[localStorage.getItem('language')]
                                          .add_alert_cond_target_user_title
                                      }
                                    </strong>
                                    <br></br>
                                    <i>
                                      {
                                        dictionary[localStorage.getItem('language')]
                                          .add_alert_cond_target_user_subtitle
                                      }
                                      <strong>
                                        {
                                          dictionary[localStorage.getItem('language')]
                                            .add_alert_cond_target_user_subtitle_bold
                                        }
                                      </strong>
                                    </i>
                                  </Typography>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col">
                                  <FormControl
                                    variant="outlined"
                                    fullWidth
                                  >
                                    <Autocomplete
                                      id="combo-box-demo"
                                      multiple
                                      options={activity_List.users}
                                      groupBy={(option) => option.type}
                                      getOptionLabel={(option) => option.name}
                                      onChange={(event, value) =>
                                        handleChangeSelection(value, 'target_users')
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label={
                                            dictionary[
                                              localStorage.getItem('language')
                                            ].add_alert_cond_created_by_dropdown
                                          }
                                          variant="outlined"
                                          error={!!formValidation.target_users}
                                        />
                                      )}
                                      style={{ background: 'white' }}
                                    />
                                    <FormHelperText style={{ color: 'red' }}>
                                      {formValidation.target_users}
                                    </FormHelperText>
                                  </FormControl>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                          {conditions.substring(2, 3) == '1' ? (
                            <div className={"col-md-" + (12 / (Math.max((conditions.match(new RegExp("1", "g")) || []).length, 1)))}>
                              <div className="row">
                                <div className="col">
                                  <Typography
                                      variant="subtitle2"
                                      gutterBottom
                                      className="mb-3 text-center"
                                    >
                                    <strong>
                                      {
                                        dictionary[localStorage.getItem('language')]
                                          .add_alert_cond_devices_title
                                      }
                                    </strong>
                                    <br></br>
                                    <i>
                                      {
                                        dictionary[localStorage.getItem('language')]
                                          .add_alert_cond_devices_subtitle
                                      }
                                      <strong>
                                        {
                                          dictionary[localStorage.getItem('language')]
                                            .add_alert_cond_devices_subtitle_bold
                                        }
                                      </strong>
                                    </i>
                                  </Typography>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col">
                                  <FormControl
                                    variant="outlined"
                                    fullWidth
                                  >
                                    <Autocomplete
                                      id="combo-box-demo"
                                      multiple
                                      options={activity_List.devices}
                                      groupBy={(option) => option.type}
                                      getOptionLabel={(option) => option.name}
                                      onChange={(event, value) =>
                                        handleChangeSelection(value, 'devices')
                                      }
                                      renderOption={(option) => (
                                        <div style={{ display: 'flex' }}>
                                          <img
                                            alt=""
                                            src={
                                              option.type == 'Device' || option.type == ''
                                                ? `../assets/img/lock.png`
                                                : `../assets/img/lock-group.png`
                                            }
                                            className={classes.lockImgSize}
                                          />
                                          <span variant="subtitle2">
                                            {option.name}
                                          </span>
                                        </div>
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label={
                                            dictionary[
                                              localStorage.getItem('language')
                                            ].device_selection
                                          }
                                          variant="outlined"
                                          error={!!formValidation.devices}
                                        />
                                      )}
                                      style={{ background: 'white' }}
                                    />
                                    <FormHelperText style={{ color: 'red' }}>
                                      {formValidation.devices}
                                    </FormHelperText>
                                  </FormControl>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                          {(conditions.match(new RegExp("1", "g")) || []).length == 0 ? (
                            <Typography
                                variant="subtitle2"
                                className="ml-3 text-center"
                              >
                              <i>
                                {
                                  dictionary[localStorage.getItem('language')]
                                    .add_alert_cond_none
                                }
                              </i>
                            </Typography>
                          ) : (
                            ''
                          )}
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                </div>
                {/* 3. Set Trigger Schedule */}
                <div className="row mb-3">
                  <div
                    className="col-md-6"
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <Typography
                      variant="h6"
                    >
                      <strong>
                        {
                          dictionary[localStorage.getItem('language')]
                            .add_alert_step_3_header
                        }
                      </strong>
                    </Typography>
                    <RadioGroup
                      aria-label="schedule"
                      name="schedule"
                      value={values.schedule}
                      onChange={handleChangeSchedule2('schedule')}
                    >
                      <div className="ml-4">
                        <div className="row">
                          <Typography variant="subtitle2">
                            <FormControlLabel
                              style={{ marginRight: 0 }}
                              value={'any'}
                              control={
                                <Radio
                                  color="primary"
                                  disabled={false}
                                />
                              }
                            />
                            <strong>
                              {
                                dictionary[localStorage.getItem('language')]
                                  .add_alert_step_3_option_1
                              }
                            </strong>
                          </Typography>
                        </div>
                        <div className="row">
                          <Typography variant="subtitle2">
                            <FormControlLabel
                              style={{ marginRight: 0 }}
                              value={'custom'}
                              control={
                                <Radio
                                  color="primary"
                                  disabled={false}
                                />
                              }
                            />
                            <strong>
                              {
                                dictionary[localStorage.getItem('language')]
                                  .add_alert_step_3_option_2
                              }
                            </strong>
                          </Typography>
                        </div>
                      </div>
                    </RadioGroup>
                    <FormHelperText className="ml-3" style={{ color: 'red' }}>
                      {formValidation.schedule}
                    </FormHelperText>
                    {values.schedule == 'custom' ? (
                    <Card
                      variant="outlined"
                      style={{ border: '2px solid #b0b0b0', background: '#F8F8F8' }}
                    >
                      <CardContent>
                        <div className="row ml-1">
                          <Typography
                              variant="subtitle2"
                              gutterBottom
                              className="mb-3 text-center"
                            >
                            <i>
                              {
                                dictionary[localStorage.getItem('language')]
                                  .add_alert_step_3_option_2_subtitle
                              }
                            </i>
                          </Typography>
                        </div>
                        <div className="row">
                          <div className="col-md-7">
                            <Box display="flex">
                              <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                              >
                                <DatePicker
                                  label={
                                    dictionary[
                                      localStorage.getItem('language')
                                    ].start_date_field_name
                                  }
                                  inputVariant="outlined"
                                  format="MM/dd/yyyy"
                                  value={
                                    values.startDate
                                      ? values.startDate
                                      : null
                                  }
                                  size="small"
                                  name="startDate"
                                  error={
                                    !!formValidation.startDate
                                  }
                                  helperText={
                                    formValidation.startDate
                                  }
                                  onChange={(value) =>
                                    handleChangeSchedule(
                                      value,
                                      'startDate'
                                    )
                                  }
                                  className="mr-2"
                                  style={{ background: '#FFFFFF' }}
                                />

                                <DatePicker
                                  label={
                                    dictionary[
                                      localStorage.getItem('language')
                                    ].end_date_field_name
                                  }
                                  inputVariant="outlined"
                                  format="MM/dd/yyyy"
                                  value={
                                    values.endDate
                                      ? values.endDate
                                      : null
                                  }
                                  size="small"
                                  name="endDate"
                                  error={!!formValidation.endDate}
                                  helperText={
                                    formValidation.endDate
                                  }
                                  disabled={isEndDateDisabled}
                                  InputProps={
                                    isEndDateDisabled
                                      ? {
                                          className:
                                            classes.disabledColor,
                                        }
                                      : null
                                  }
                                  onChange={(value) =>
                                    handleChangeSchedule(
                                      value,
                                      'endDate'
                                    )
                                  }
                                  style={{ background: '#FFFFFF' }}
                                />
                              </MuiPickersUtilsProvider>
                              <Box display="flex">
                                <FormControlLabel
                                  control={
                                    <CheckboxWithGreenCheck
                                      name="isNeverEnd"
                                      color="primary"
                                      checked={values.isNeverEnd}
                                    />
                                  }
                                  value={values.isNeverEnd}
                                  style={{
                                    marginLeft: 0,
                                    marginRight: -5,
                                    marginBottom: 0,
                                    marginTop: 0,
                                  }}
                                  onChange={handleChangeSchedule2(
                                    'isNeverEnd'
                                  )}
                                />
                                <Typography
                                  variant="subtitle2"
                                  className="mr-4"
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <span
                                    style={{
                                      whiteSpace: 'nowrap',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <strong>
                                      {
                                        dictionary[
                                          localStorage.getItem(
                                            'language'
                                          )
                                        ].never_expires_option
                                      }
                                    </strong>
                                  </span>
                                </Typography>
                              </Box>
                            </Box>
                          </div>
                        </div>
                        <div className="row mt-2 ml-1">
                          <FormControl
                            component="fieldset"
                            error={!!formValidation.days}
                          >
                            <RadioGroup
                              row
                              aria-label="position"
                              name="position"
                              defaultValue="top"
                              className="radioGroup"
                            >
                              <Typography variant="subtitle2">
                                <strong>
                                  {
                                    dictionary[
                                      localStorage.getItem('language')
                                    ].monday_abbreviation
                                  }
                                </strong>
                              </Typography>
                              <FormControlLabel
                                control={
                                  <CheckboxWithGreenCheck
                                    name="mon"
                                    color="primary"
                                    checked={values.mon}
                                  />
                                }
                                value={values.mon}
                                style={{
                                  marginLeft: -32,
                                  marginTop: 18,
                                }}
                                onChange={handleChangeSchedule2('mon')}
                              />

                              <Typography variant="subtitle2">
                                <strong>
                                  {
                                    dictionary[
                                      localStorage.getItem('language')
                                    ].tuesday_abbreviation
                                  }
                                </strong>
                              </Typography>
                              <FormControlLabel
                                control={
                                  <CheckboxWithGreenCheck
                                    name="tue"
                                    color="primary"
                                    checked={values.tue}
                                  />
                                }
                                value={values.tue}
                                style={{
                                  marginLeft: -32,
                                  marginTop: 18,
                                }}
                                onChange={handleChangeSchedule2('tue')}
                              />

                              <Typography variant="subtitle2">
                                <strong>
                                  {
                                    dictionary[
                                      localStorage.getItem('language')
                                    ].wednesday_abbreviation
                                  }
                                </strong>
                              </Typography>
                              <FormControlLabel
                                control={
                                  <CheckboxWithGreenCheck
                                    name="wed"
                                    color="primary"
                                    checked={values.wed}
                                  />
                                }
                                value={values.wed}
                                style={{
                                  marginLeft: -32,
                                  marginTop: 18,
                                }}
                                onChange={handleChangeSchedule2('wed')}
                              />

                              <Typography variant="subtitle2">
                                <strong>
                                  {
                                    dictionary[
                                      localStorage.getItem('language')
                                    ].thursday_abbreviation
                                  }
                                </strong>
                              </Typography>
                              <FormControlLabel
                                control={
                                  <CheckboxWithGreenCheck
                                    name="thu"
                                    color="primary"
                                    checked={values.thu}
                                  />
                                }
                                value={values.thu}
                                style={{
                                  marginLeft: -32,
                                  marginTop: 18,
                                }}
                                onChange={handleChangeSchedule2('thu')}
                              />

                              <Typography variant="subtitle2">
                                <strong>
                                  {
                                    dictionary[
                                      localStorage.getItem('language')
                                    ].friday_abbreviation
                                  }
                                </strong>
                              </Typography>
                              <FormControlLabel
                                control={
                                  <CheckboxWithGreenCheck
                                    name="fri"
                                    color="primary"
                                    checked={values.fri}
                                  />
                                }
                                value={values.fri}
                                style={{
                                  marginLeft: -32,
                                  marginTop: 18,
                                }}
                                onChange={handleChangeSchedule2('fri')}
                              />

                              <Typography variant="subtitle2">
                                <strong>
                                  {
                                    dictionary[
                                      localStorage.getItem('language')
                                    ].saturday_abbreviation
                                  }
                                </strong>
                              </Typography>
                              <FormControlLabel
                                control={
                                  <CheckboxWithGreenCheck
                                    name="sat"
                                    color="primary"
                                    checked={values.sat}
                                  />
                                }
                                value={values.sat}
                                style={{
                                  marginLeft: -32,
                                  marginTop: 18,
                                }}
                                onChange={handleChangeSchedule2('sat')}
                              />

                              <Typography variant="subtitle2">
                                <strong>
                                  {
                                    dictionary[
                                      localStorage.getItem('language')
                                    ].sunday_abbreviation
                                  }
                                </strong>
                              </Typography>
                              <FormControlLabel
                                control={
                                  <CheckboxWithGreenCheck
                                    name="sun"
                                    color="primary"
                                    checked={values.sun}
                                  />
                                }
                                value={values.sun}
                                style={{
                                  marginLeft: -32,
                                  marginTop: 18,
                                }}
                                onChange={handleChangeSchedule2('sun')}
                              />
                            </RadioGroup>
                            <FormHelperText>
                              {formValidation.days}
                            </FormHelperText>
                          </FormControl>
                        </div>
                        <div className="row">
                          <div className="col-md-12 mt-2 ml-1">
                            <Box display="flex">
                              <Box display="flex">
                                <FormControlLabel
                                  control={
                                    <CheckboxWithGreenCheck
                                      name="isAllDay"
                                      color="primary"
                                      checked={values.isAllDay}
                                    />
                                  }
                                  value={values.isAllDay}
                                  style={{
                                    marginLeft: -10,
                                    marginRight: -5,
                                    marginBottom: 0,
                                    marginTop: 0,
                                  }}
                                  onChange={handleChangeSchedule2('isAllDay')}
                                />
                                <Typography
                                  variant="subtitle2"
                                  className="mr-4"
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <span
                                    style={{
                                      whiteSpace: 'nowrap',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <strong>
                                      {
                                        dictionary[
                                          localStorage.getItem('language')
                                        ].all_day_option
                                      }
                                    </strong>
                                  </span>
                                </Typography>
                              </Box>
                              <Box
                                display="inline-flex"
                                flexDirection="column"
                                className="mr-2"
                              >
                                <Box display="flex">
                                  <TimePicker
                                    placeholder={
                                      dictionary[
                                        localStorage.getItem('language')
                                      ].start_time_field_name
                                    }
                                    showSecond={false}
                                    focusOnOpen={true}
                                    format="HH:mm"
                                    value={dispatchStartTime}
                                    onChange={(value) =>
                                      handleValueChange(
                                        value,
                                        'startTime'
                                      )
                                    }
                                    disabled={isTimeDisabled}
                                    clearText="Clear"
                                    className="mr-2"
                                    error={
                                      !!(
                                        formValidation.startTime ||
                                        formValidation.endTime
                                      )
                                    }
                                  />
                                  <TimePicker
                                    placeholder={
                                      dictionary[
                                        localStorage.getItem('language')
                                      ].end_time_field_name
                                    }
                                    showSecond={false}
                                    focusOnOpen={true}
                                    format="HH:mm"
                                    value={dispatchEndTime}
                                    onChange={(value) =>
                                      handleValueChange(
                                        value,
                                        'endTime'
                                      )
                                    }
                                    disabled={isTimeDisabled}
                                    clearText="Clear"
                                    error={
                                      !!(
                                        formValidation.startTime ||
                                        formValidation.endTime
                                      )
                                    }
                                  />
                                </Box>
                                <FormControl
                                  variant="outlined"
                                  fullWidth
                                  error={
                                    !!(
                                      formValidation.startTime ||
                                      formValidation.endTime
                                    )
                                  }
                                >
                                  <FormHelperText>
                                    {formValidation.endTime}
                                  </FormHelperText>
                                </FormControl>
                              </Box>
                              <FormControl
                                variant="outlined"
                                fullWidth
                                error={!!formValidation.zone}
                              >
                                <Autocomplete
                                  size="small"
                                  style={{ width: 270, background: '#FFFFFF' }}
                                  id="combo-box-demo"
                                  value={values.zone}
                                  options={timeZone_List.map(
                                    (it) => it.id
                                  )}
                                  onChange={(event, value) =>
                                    handleChangeSelection(value, 'zone')
                                  }
                                  getOptionLabel={(option) =>
                                    timeZone_List.find(
                                      (it) => it.id === option
                                    )?.full_time_zone
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={
                                        dictionary[
                                          localStorage.getItem('language')
                                        ].time_zone_field_name
                                      }
                                      variant="outlined"
                                      error={!!formValidation.zone}
                                    />
                                  )}
                                />
                                <FormHelperText>
                                  {formValidation.zone}
                                </FormHelperText>
                              </FormControl>
                            </Box>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                {/* 4. Select Email Recipients */}
                <div className="row mb-3">
                  <div
                    className="col-md-6"
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      className="mb-3"
                    >
                      <strong>
                        {
                          dictionary[localStorage.getItem('language')]
                            .add_alert_step_4_header
                        }
                      </strong>
                    </Typography>
                    <Card
                      variant="outlined"
                      style={{ border: '2px solid #b0b0b0', background: '#F8F8F8' }}
                    >
                      <CardContent>
                        <div className="row ml-1">
                          <Typography
                              variant="subtitle2"
                              gutterBottom
                              className="mb-3 text-center"
                            >
                            <i>
                              {
                                dictionary[localStorage.getItem('language')]
                                  .add_alert_step_4_subtitle
                              }
                            </i>
                          </Typography>
                        </div>
                        <FormControl
                            variant="outlined"
                            fullWidth
                          >
                            <Autocomplete
                              id="combo-box-demo"
                              multiple
                              freeSolo
                              options={activity_List.users}
                              groupBy={(option) => option.type}
                              getOptionLabel={(option) => option.name + ((option.type == 'By User') ? ' (' + option.e_mail + ')' : '')}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={
                                    dictionary[
                                      localStorage.getItem('language')
                                    ].add_alert_step_4_dropdown
                                  }
                                  variant="outlined"
                                  error={!!formValidation.emails}
                                />
                              )}
                              filterOptions={(options, params) => {
                                const filtered = filter(options, params)
                                const { inputValue } = params

                                const isExisting = options.some((option) => inputValue === option.name)
                                if (inputValue !== '' && !isExisting) {
                                  filtered.push({
                                    inputValue,
                                    name: `${inputValue}`,
                                    type: 'Email',
                                  })
                                }

                                return filtered
                              }}
                              onChange={(event, value) => {
                                // If the last value is manually entered, then format it properly
                                const lastVal = value[value.length - 1]
                                if (typeof lastVal === 'string') {
                                  value[value.length - 1] = {
                                    name: lastVal,
                                    e_mail: lastVal,
                                    type: 'Email',
                                  }
                                }
                                handleChangeSelection(value, 'emails')
                              }}
                              style={{ background: 'white' }}
                            />
                            <FormHelperText style={{ color: 'red' }}>
                              {formValidation.emails}
                            </FormHelperText>
                        </FormControl>
                        <div className="row pt-3">
                          <div className="col-md-4">
                            <Button
                              variant="contained"
                              color="info"
                              onClick={handleEmailClick}
                              style={{
                                width: '80%',
                                display: props.loadingKeyAssign
                                  ? 'none'
                                  : 'block',
                              }}
                            >
                              {dictionary[localStorage.getItem('language')]
                                    .add_alert_step_4_button
                                    }
                            </Button>
                            <Button
                              variant="contained"
                              color="info"
                              onClick={handleEmailClick}
                              style={{
                                width: '80%',
                                display: props.loadingTestEmail
                                  ? 'block'
                                  : 'none',
                              }}
                            >
                              {dictionary[localStorage.getItem('language')]
                                    .add_alert_step_4_button
                                    }
                                    &nbsp;
                                    <i className="fa fa-spinner fa-spin right" />
                            </Button>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                </div>
                {/* Save Button */}
                <div className="row pt-3">
                  <div className="col-md-2">
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={handleSubmitClick}
                      style={{
                        width: '80%',
                        display: props.loadingKeyAssign
                          ? 'none'
                          : 'block',
                      }}
                    >
                      {dictionary[localStorage.getItem('language')]
                            .add_alert_save_button
                      }
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      style={{
                        width: '80%',
                        display: props.loadingKeyAssign
                          ? 'block'
                          : 'none',
                      }}
                    >
                      {dictionary[localStorage.getItem('language')]
                            .add_alert_save_button
                        }
                      &nbsp;
                      <i className="fa fa-spinner fa-spin right" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dialog
          open={confirmOpen.show}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {dictionary[localStorage.getItem('language')].alert_email_popup_title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dictionary[localStorage.getItem('language')]
                .alert_email_popup_subtitle}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color=""
              variant="contained"
              size="small"
            >
              {
                dictionary[localStorage.getItem('language')]
                  .alert_email_popup_cancel_option
              }
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={sendEmail}
              color="primary"
              autoFocus
              style={{ display: confirmOpen.loadingPopup ? 'none' : 'block' }}
            >
              {
                dictionary[localStorage.getItem('language')]
                  .alert_email_popup_send_option
              }
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              autoFocus
              style={{ display: confirmOpen.loadingPopup ? 'block' : 'none' }}
            >
              {
                dictionary[localStorage.getItem('language')]
                  .alert_email_popup_send_option
              }{' '}
              &nbsp;
              <i className="fa fa-spinner fa-spin right" />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.user.loading,
    error: state.user.error,
    success: state.user.success,
  }
}

AddUsers.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
}

// export default (withSnackbar(AddUsers));
export default connect(mapStateToProps)(withSnackbar(AddUsers))
