import React, { useEffect, useState, useMemo } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import { Person } from '@material-ui/icons'
import {
  DataGrid,
  GridColDef,
  GridApi,
  GridCellValue,
  GridCellParams,
} from '@material-ui/data-grid'
import { Button, Checkbox, Typography, IconButton } from '@material-ui/core'
import 'react-toastify/dist/ReactToastify.css'
import PropTypes from 'prop-types'
import { connect, useSelector, useDispatch } from 'react-redux'
import {
  alertList,
  deleteAlert,
  resetErrorAndSuccessState,
} from '../store/actions/userActions'
import { withSnackbar } from 'notistack'
import {
  Group,
  Devices,
  DevicesOther,
  Description,
  ContactSupport,
  Settings,
  Delete,
  Create,
} from '@material-ui/icons'
// import UserDetails from "./UserDetails";
import { useHistory } from 'react-router-dom'
import Loader from '../PageLoader'
import Footer from '../footer'
import Submenu from '../header_sub_menu'
import moment from 'moment'
import Tooltip from '@material-ui/core/Tooltip'
import dictionary from '../store/dictionary.json'
// confirm dialog
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

const AlertsList = (props) => {
  const { enqueueSnackbar, error, success, loading, pageLoading } = props
  const dispatch = useDispatch()
  const { alert_List } = useSelector((state) => state.user)
  const { permissionDetails } = useSelector((state) => state.auth)
  const history = useHistory()
  const [isLoad, setIsLoad] = useState(false)

  const [confirmOpen, setConfirmOpen] = React.useState({
    show: false,
    key: '',
    item: {},
    loadingDelete: false,
  })

  // confirm dialog
  const handleClickOpen = (item) => {
    setConfirmOpen({
      show: true,
      key: item.name,
      item: item,
      loadingDelete: false,
    })
  }
  
  const handleClose = () => {
    setConfirmOpen({
      show: false,
      key: '',
      item: {},
      loadingDelete: false,
    })
  }

  const confirmDelete = () => {
    if (confirmOpen.item) {
      setConfirmOpen({ ...confirmOpen, loadingDelete: true })
      const item = confirmOpen.item
      dispatch(deleteAlert(item.id))
    }
  }

  setTimeout(function () {
    setIsLoad(true)
  }, 0)

  const columns = [
    {
      field: '#',
      headerName: '',
      width: 80,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => <strong>{''}</strong>,
      renderCell: (params) => (
        <Tooltip
          title={
            dictionary[localStorage.getItem('language')].alert_details_tooltip
          }
          arrow
        >
          <img
          alt="Active"
          src="../assets/img/alerts_blue.svg"
          style={{ cursor: 'pointer', width: 30 }}
        />
        </Tooltip>
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 250,
      renderHeader: () => (
        <strong>
          {dictionary[localStorage.getItem('language')].alert_name_header}
        </strong>
      ),
    },
    {
      field: 'activityName',
      headerName: 'Trigger',
      width: 250,
      renderHeader: () => (
        <strong>
          {dictionary[localStorage.getItem('language')].trigger_on_header}
        </strong>
      ),
      renderCell: (rows) => rows.row.activity.name
    },
    {
      field: 'lastActivity',
      headerName: 'Timestamp',
      width: 250,
      sortable: false,
      renderHeader: () => (
        <strong>
          {dictionary[localStorage.getItem('language')].date_last_run_header}
        </strong>
      ),
      renderCell: (rows) => (
        moment(rows.row.lastActivity).format('MM/DD/YYYY HH:mm') == "Invalid date" 
          ? "-" 
          : (
            <Tooltip
                title={
                  dictionary[localStorage.getItem('language')].last_activity_tooltip
                }
                arrow
              >
                <Link>{moment(rows.row.lastActivity).format('MM/DD/YYYY HH:mm')}</Link>
            </Tooltip>
          )
      )
    },
    {
      field: 'del',
      headerName: '',
      width: 80,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => <strong>{''}</strong>,
      renderCell: (rows) => (
        <Tooltip
          title={
            dictionary[localStorage.getItem('language')].alert_delete_tooltip
          }
          arrow
        >
          <IconButton
            edge="end"
            aria-label="delete"
            color="secondary"
            onClick={() => handleClickOpen(rows.row)}
          >
            <Delete />
          </IconButton>
        </Tooltip>
      ),
    },
  ]

  const currentlySelected = (GridCellParams) => {
    const action = GridCellParams.colDef.field
    const alertId = GridCellParams.row.id
    if (action == '#') {
      localStorage.setItem('tempId', alertId)
      const uID = Date.now()
      localStorage.setItem("uID", uID)
      history.push({
        pathname: '/modifyAlert',
        isLoaderRequired: true,
      })
    } else if (action == 'activityName') {
      // var row = GridCellParams.row
      // if (row.activity.name) {
      //   history.push({
      //     pathname: 'activity',
      //     state: { id: row.activity.id, type: 'ActivityType' },
      //   })
      // }
    } else if (action == 'lastActivity') {
      var row = GridCellParams.row
      if (row.lastActivityID) {
        history.push({
          pathname: 'activity',
          state: { id: row.lastActivityID, type: 'ActivityID' },
        })
      }
    }
  }

  useEffect(() => {
    dispatch(alertList(true))
  }, [dispatch])

  useEffect(() => {
    error && enqueueSnackbar(error, { variant: 'error' })
  }, [error])

  useEffect(() => {
    success && enqueueSnackbar(success, { variant: 'success' })
    dispatch(resetErrorAndSuccessState())
    if (success == 'Alert deleted') {
      dispatch(alertList(true))
      handleClose()
    }
  }, [success])

  return (
    <div>
      {pageLoading ? <Loader /> : null}

      <Submenu />
      <div id="Contborder" className="Contborder">
        <div className="row" style={{ height: '100%' }}>
          <div className="col-md-12" style={{ height: '100%' }}>
            <div className="card" style={{ marginBottom: 0, height: '100%' }}>
              <div
                className="card-header border-0"
                style={{ padding: '12px 25px 0 0' }}
              >
                <div className="row align-items-center">
                  <div className="col-lg-6"></div>
                  <div className="col-lg-6">
                    {permissionDetails.permission_all ||
                    permissionDetails.permission_add ? (
                      <Link class="btn btn-primary float-right" to="/addAlert">
                        {
                          dictionary[localStorage.getItem('language')]
                            .add_alert_button
                        }
                      </Link>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>

              <div className="card-body" style={{ height: 520, width: '100%' }}>
                {isLoad ? (
                  <DataGrid
                    onCellClick={currentlySelected}
                    rows={alert_List}
                    columns={columns}
                    pageSize={25}
                    loading={props.loading}
                    disableColumnSelector
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
        <Dialog
          open={confirmOpen.show}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {dictionary[localStorage.getItem('language')].delete_alert_title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dictionary[localStorage.getItem('language')]
                .delete_alert_subtitle +
                ' ' +
                confirmOpen.key}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color=""
              variant="contained"
              size="small"
            >
              {
                dictionary[localStorage.getItem('language')]
                  .delete_ekey_cancel_option
              }
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={confirmDelete}
              color="secondary"
              autoFocus
              style={{ display: confirmOpen.loadingDelete ? 'none' : 'block' }}
            >
              {
                dictionary[localStorage.getItem('language')]
                  .delete_ekey_delete_option
              }
            </Button>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              autoFocus
              style={{ display: confirmOpen.loadingDelete ? 'block' : 'none' }}
            >
              {
                dictionary[localStorage.getItem('language')]
                  .delete_ekey_delete_option
              }{' '}
              &nbsp;
              <i className="fa fa-spinner fa-spin right" />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.user.loading,
    error: state.user.error,
    success: state.user.success,
    pageLoading: state.user.pageLoading,
  }
}

AlertsList.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(withSnackbar(AlertsList))
